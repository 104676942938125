html,
body {
  min-height: 100vh;
  height: auto;
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.map-marker {
  //background:red;
  //border:5px solid rgba(255,255,255,0.5);
  //color:blue;
  //font-weight:bold;
  //text-align:center;
  //border-radius:50%;
  //line-height:30px;

  font-size: xx-large;
}
